<template>
  <div class="sign-custom-container" v-show="notSign" @click="closeShowTip">
    <div class="sign-custom-back"></div>
    <div class="sign-custom-main">
      <div style="text-align: center">
        <img
          style="width: 50%; height: 100%"
          :src="require('@/assets/images/live/sign.png')"
          alt=""
        />
      </div>

      <div class="sign-custom-title-second">会议报名及签到中心</div>
      <div class="sign-custom-content global-shadow">
        <!-- <img class="sign-doctor"  :src="require('@/assets/images/live/doctor.png')"  alt="" /> -->
        <div class="sign-icon">
          <img
            class="sign-icon-item"
            :src="require('@/assets/images/live/logo.jpg')"
            alt=""
          />
        </div>
        <div class="sign-form">
          <div class="sign-row">
            <div class="sign-row-key">
              <span v-for="(text, index) in '姓名'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <el-input
                v-model.trim="data.name"
                placeholder="请输入姓名"
                @input="onInput"
                @clear="doClear"
              >
              </el-input>
              <div v-if="isShowDoc" class="signIn_mate_block" @click.stop="">
                <!-- <div class="signIn_remind">提醒:</div>
              <div class="signIn_remind">
                匹配后无需再完善专家已有信息，请慎重选择！
              </div> -->
                <div
                  class="signIn_mate_doctor"
                  :style="{
                    height: `${
                      config.AllDoctor.length == 0 ? '50px' : '160px'
                    }`,
                  }"
                >
                  <item-scroll ref="ItemScrollDoctor">
                    <div style="">
                      <div
                        class="signIn_mate_doctor_item"
                        v-for="(item, i) in config.AllDoctor"
                        :key="i"
                        @click="selectExpert(item)"
                      >
                        {{ item.name }}-{{ item.hospital }}
                      </div>
                      <div
                        style="color: #b53c93"
                        class="signIn_mate_doctor_item"
                        @click="selectExpert()"
                      >
                        <div class="signIn_mate_doctor_btn">
                          {{
                            config.AllDoctor.length > 0
                              ? "以上均不是我的信息，我要新增"
                              : "暂无该医生信息，请新增"
                          }}
                        </div>
                      </div>
                    </div>
                  </item-scroll>
                </div>
              </div>
              <CustomButton
                v-if="false"
                size="small"
                class="match_btn"
                @click="intelligentMatching"
                >智能匹配
              </CustomButton>
            </div>
          </div>
          <div class="sign-row">
            <div class="sign-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '省份'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <el-select
                v-model="data.province"
                placeholder="请选择省份"
                @change="changeProvince()"
              >
                <el-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sign-row">
            <div class="sign-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '城市'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <el-select
                v-model="data.city"
                @change="changeCity"
                placeholder="请选择城市"
              >
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="sign-row">
            <div class="sign-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '医院'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <el-select
                v-model="data.hospital"
                filterable
                allow-create
                placeholder="请选择医院"
              >
                <el-option
                  v-for="(item, index) in config.hospitalList"
                  :key="index"
                  :label="item.hospital"
                  :value="item.hospital"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="sign-row">
            <div class="sign-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '科室'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <el-select
                v-model="data.department"
                filterable
                allow-create
                placeholder="请选择科室"
              >
                <el-option
                  v-for="(item, index) in departmentList"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="sign-row">
            <div class="sign-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '手机号'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <el-input
                v-model.trim="data.phone"
                @input="changePhone"
                placeholder="请输入手机号"
              >
              </el-input>
            </div>
          </div>
          <div class="sign-row">
            <div class="sign-row-key">
              <!-- <span class="global-point">*</span> -->
              <span v-for="(text, index) in '职务'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>

            <div class="sign-row-value">
              <el-select v-model="data.job_name" placeholder="请选择职称资料">
                <el-option
                  v-for="(item, index) in config.jobs"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sign-row">
            <div class="sign-row-key">
              <span v-for="(text, index) in '职称资料'" :key="text"
                ><span class="global-point" v-if="index == 0">*</span
                >{{ text }}</span
              >
            </div>
            <div class="sign-row-value">
              <div
                class="signIn_info_image-select"
                @click="selectFileImg('identity_proof')"
              >
                <span v-if="identity_proof">
                  {{ identity_proof }}
                </span>
                <span v-else> 点击上传医生资质证明 </span>
              </div>
            </div>
          </div>
        </div>
        <div class="sign-custom-btn-group global-center">
          <CustomButton class="sign-custom-btn" @click="doSign" round
            >进入直播</CustomButton
          >
        </div>
      </div>
    </div>
    <input
      type="file"
      v-show="false"
      v-if="resetFile"
      @change="changeFileImg"
      ref="selectFileImg"
    />
  </div>
</template>

<script>
import weixin from "@/common/weixin";
import uploads from "@/common/uploads";
import CustomButton from "@/components/unit/CustomButton.vue";
import ItemScroll from "@/components/unit/ItemScroll";
import department from "@/common/department.json";
export default {
  name: "Sign",
  components: {
    CustomButton,
    ItemScroll,
  },
  data() {
    return {
      isShowDoc: false,
      identity_proof: "",
      resetFile: true,
      data: {
        meet_id: 0,
        device_code: "",
        name: "",
        province: "",
        city: "",
        hospital: "",
        device: "",
        os_version: "",
        width: "",
        height: "",
        department: "",
        job_name: "",
        phone: "",
        identity_proof: "",
      },
      isSign: false,
      config: {
        citys: [],
        hospitalList: [],
        jobs: ["主任医师", "副主任医师", "主治医师", "医师（住院医师）"],
      },
      query: {},
      notSign: false,
      intelligent_matching: 0, //标记当前这个观众的来源 来源类型，0 自己填，1 观众库
      comparativeData: {}, //匹配对比数据
      comparativeKey: [
        "name",
        "province",
        "city",
        "hospital",
        "department",
        "job_name",
        "identity_proof",
      ], //匹配对比数据
      departmentList: department,
    };
  },
  computed: {
    provinces() {
      return this.config.citys.map((item) => item.name);
    },
    citys() {
      if (!this.data.province) return [];
      let index = this.provinces.indexOf(this.data.province);
      if (index == -1) return [];

      return this.config.citys[index].sub.map((item) => item.name);
    },
  },
  async created() {
    this.config.citys = await this.$tools.getCityConfig();
    this.query = this.$route.query;
    let signInfo = {};
    let data = {
      ...this.$tools.getSystemInfo(),
      id: this.query.meetId,
    };
    // if (this.query.loginType == 1) {
    //   this.goToApp();
    //   return;
    // }
    let url = this.$tools.getURL(this.$urls.live.info, data);
    try {
      let response = await this.$axios.get(url);
      console.log(response);
      if (this.query.loginType == 1) {
        if (response.data.inspection_valid == 0) {
          this.$tips.error(
            { text: "该会议暂不支持飞检，请联系运营人员" },
            60 * 1000
          );
          return;
        }
        this.goToApp();
        return;
      }
      if (this.query.loginType == 2) {
        this.goToApp();
        return;
      }
      if (response.data && response.data.openid) {
        this.data = response.data;
        this.data.name = this.data.sign_name;
        this.identity_proof = this.data.identity_proof.split("/").pop();
        this.notSign = true;
        this.comparativeData = { ...response.data };
        this.intelligent_matching = response.data.intelligent_matching;
        // signInfo[this.query.meetId] = response.data;
        // this.$store.dispatch("setItem", { signInfo });
        // this.goToApp();
      } else {
        this.notSign = true;
      }

      this.loadHospital();
    } catch (err) {
      console.log(err);
      this.notSign = true;
    }
  },
  methods: {
    verifyData() {
      if (!this.data.name) {
        this.$tips.error({ text: "请输入姓名" });
        return false;
      }
      if (!this.data.province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.data.city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.data.hospital) {
        this.$tips.error({ text: "请输入医院" });
        return false;
      }
      if (!this.data.department) {
        this.$tips.error({ text: "请输入科室" });
        return false;
      }
      if (!this.data.phone) {
        this.$tips.error({ text: "请输入手机号" });
        return false;
      }
      if (!/^1\d{10}$/.test(this.data.phone)) {
        this.$tips.error({ text: "请输入正确的手机号" });
        return false;
      }
      if (!this.data.job_name) {
        this.$tips.error({ text: "请选择职务" });
        return false;
      }
      if (!this.data.identity_proof) {
        this.$tips.error({ text: "请上传职称资料" });
        return false;
      }
      return true;
    },
    selectFileImg(key) {
      this.selectKey = key;

      this.$refs.selectFileImg.click();
    },
    //上传图片(不裁图)
    async changeFileImg(event) {
      let reg = "jpg|jpeg|png|PNG|JPG|JPEG";
      let file = event.target.files[0];
      if (!file) return;
      let RegObj = new RegExp(`^(${reg})$`);
      let type = file.name.split(".").slice(-1);
      if (!RegObj.test(type)) {
        this.$tips.error({ text: `类型必须是${reg.replace(/\|/g, ",")}` });
        return "";
      }
      this.resetFile = false;
      let loading = this.$tips.loading();
      this.$nextTick(() => {
        this.resetFile = true;
      });

      try {
        let res = await uploads.uploadBase64({
          file: await this.$tools.compressImage(file, true),
        });
        loading.close();
        this.data[this.selectKey] = res;

        this.identity_proof = res.split("/").pop();
      } catch (err) {
        loading.close();
      }
    },
    changePhone() {
      this.data.phone = this.data.phone.replace(/\D/g, "").slice(0, 11);
    },
    changeProvince() {
      this.data.city = "";
      this.data.hospital = "";
    },
    changeCity() {
      this.loadHospital();
      this.data.hospital = "";
    },
    loadHospital() {
      let data = {
        provine: this.data.province,
        city: this.data.city,
      };
      let url = this.$tools.getURL(this.$urls.hospital.hospitalList, data);
      this.$axios.get(url).then((res) => {
        this.config.hospitalList = res.data;
      });
    },

    //签到信息比较 是否有修改
    getComparativeData() {
      for (let index = 0; index < this.comparativeKey.length; index++) {
        const element = this.comparativeKey[index];
        if (this.comparativeData[element] != this.data[element]) {
          this.intelligent_matching = 0;
          break;
        } else {
          this.intelligent_matching = 1;
        }
      }
    },
    doSign() {
      if (this.comparativeData.name && this.intelligent_matching == 1) {
        this.getComparativeData();
      }
      if (!this.verifyData() || this.loading) return;
      this.loading = true;
      let data = {
        ...this.data,
        ...this.$tools.getSystemInfo(),
        meet_id: this.query.meetId,
        intelligent_matching: this.intelligent_matching,
      };
      data.height = String(data.height);
      data.width = String(data.width);
      let name = this.data.name ? encodeURIComponent(this.data.name) : "";
      let openid = `${this.$tools.getCookie("openid")}|${name}`;

      this.$axios
        .post(this.$urls.live.sign, data, { openid })
        // this.$axios.post(this.$urls.live.sign, data)
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.goToApp(res.openid);
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    goToApp(tmpOpenid = "") {
      let query = this.$route.query;
      let name = this.data.name ? encodeURIComponent(this.data.name) : "";
      let openid = tmpOpenid || `${this.$tools.getCookie("openid")}|${name}`;
      query.openid = openid;
      query.type = 0;
      query.sign = 1;
      query.loginType =
        query.loginType == 0 || query.loginType == undefined ? 0 : 1;
      weixin.wx.ready(() => {
        let url = this.$tools.getURL("/pages/guest/common/index", query);
        weixin.wx.miniProgram.redirectTo({ url });
      });
    },
    closeShowTip() {
      this.isShowDoc = false;
    },
    onInput(e) {
      if (e.length >= 2) {
        this.intelligentMatching();
      }
      if (e.length == 1) {
        this.isShowDoc = false;
        this.data = { name: e };
        this.identity_proof = "";
      }
      if (e.length == 0) {
        this.doClear();
      }
    },
    doClear() {
      this.data = {};
      this.identity_proof = "";
      this.isShowDoc = false;
    },
    //智能匹配
    intelligentMatching() {
      if (this.data.name.length < 2) {
        this.$tips.error({ text: "请输入专家完整名字" });
        return false;
      }
      let url = this.$tools.getURL(this.$urls.live.matchSignDoctor, {
        doctor_name: this.data.name,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config.AllDoctor = res.data;
          this.isShowDoc = res.type == "ok" ? true : false;
          setTimeout(() => {
            this.$refs.ItemScrollDoctor.initScroll();
          }, 500);
        })
        .catch((err) => {
          // if (err.msg == "匹配医生次数已达上限" && err.type == "error") {
          //   this.isShowDoc = true;
          // }
        });
    },
    //选择专家(根据智能匹配或者变更专家弹出不同的框)
    async selectExpert(item) {
      if (item) {
        this.comparativeData = item;
        this.intelligent_matching = item.intelligent_matching;
        // if (!this.config.jobs.includes(item.job_name)) {
        //  item.job_name = "其他";
        //}
        this.data = { ...this.data, ...item };
        this.data.identity_proof = this.data.identity_proof.split(",")[0];
        this.identity_proof = this.data.identity_proof.split("/").pop();
      } else {
        let name = this.data.name;
        this.data = { name };
        this.identity_proof = "";
        this.comparativeData = {};
        this.intelligent_matching = 0;
      }
      this.data.phone = "";
      this.isShowDoc = false;
    },
  },
};
</script>

<style lang="scss">
.sign-custom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  background-image: url("../../../assets/images/bg.png");
  background-size: 100% 100%;
}

.sign-custom-content {
  // width:88%;
  // height:88%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  border-radius: 8px;
  margin-top: 15px;
  position: relative;
}

.sign-doctor {
  width: 75px;
  height: 75px;
  position: absolute;
  right: 0;
  top: -45px;
}

.sign-custom-back {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #f5f5f5;
  opacity: 0.2;
}

.sign-custom-main {
  position: relative;
  z-index: 2;
}

.sign-form {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.sign-custom-block {
  width: 100%;
}

.sign-custom-block input {
  border: none;
  font-size: 15px;
  padding-left: 0;
  padding-right: 0;
}

.sign-custom-block-key {
  font-weight: 700;
  flex: 80px 0 0;
  font-size: 16px;
  text-align: center;
}

.sign-custom-block-value {
  flex: 1 0 0;
  border-bottom: 1px solid #f1f1f1;
}

.sign-custom-btn-group {
  width: 100%;
  margin-top: 20px;
}

.sign-custom-btn {
  width: 225px;
}

.sign-custom-block {
  height: 90px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-custom-block:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sign-custom-title {
  font-size: 30px;
  font-weight: 700;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  color: #f2674a;
}

.sign-custom-title-second {
  font-size: 18px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #f2674a;
}

.sign-icon {
  // padding-left:75px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.sign-icon-item {
  width: 45px;
  height: 45px;
}

.sign-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 40px;
}

.sign-row-key {
  width: 80px;
  font-size: 16px;
  /* text-align: right; */
  margin-right: 15px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
}

.sign-row-value {
  flex: 1 0 0;
  position: relative;
  height: 100%;
}

.sign-row-value .el-input__inner {
  // border: none;
  background: #fff;
  width: 100%;
}

.signIn_info_image-select {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #f26649;

  // padding: 0.25rem 1rem;
  border-bottom: 1px solid #ccc;
  height: 100%;
  align-items: left;
  justify-content: center;
  word-break: break-all;
}

.sign-custom-container .el-select {
  width: 100%;
}

.signIn_mate_block {
  width: 100%;
  padding: 4px;
  position: absolute;
  background: #fff;
  z-index: 999;
  top: 33px;
  right: 0;
  border: 1px solid #f26649;
  border-top: 1px solid #ccc;
  /* border-radius: 8px; */
  font-size: 14px;
}
.signIn_mate_input {
  border: 1px solid #f26649;
  border-bottom: none;
}
.signIn_mate_doctor {
  margin-top: 8px;
  width: 100%;
  height: 160px;
}
.signIn_mate_doctor_item {
  /* width: 345px; */
  padding: 0 10px;
  line-height: 1.5;
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  min-height: 40px;
  align-items: center;
}
.signIn_mate_doctor_item:last-child {
  display: flex;
  justify-content: center;
}
.signIn_mate_doctor_btn {
  background: #acc04c;
  color: #fff !important;
  text-align: center;
  border-radius: 2px;
  width: 100%;
}
</style>
